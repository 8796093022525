import { makeRoutes, MakeRoutesOpts } from "common/router/utils/routes";
import { MakePaths } from "common/router/types";
import { makeUrls } from "common/router/utils/urls";

import { AppointmentBookingStep, AppointmentConfirmationSearch } from "../types";

const paths = {
  confirm: "/appointment/confirmation",
  book: "/appointment/book/:step",
  bookConfirm: "/appointment/book-confirmation",
};

type BookingParams = {
  step: AppointmentBookingStep;
};

type BookingSearch = {
  appointment_ids?: string; // number[]
};

type BookingConfirmSearch = {
  appointment_ids: string;
};

type SchedulerPathDefinitions = {
  confirm: { Search: AppointmentConfirmationSearch };
  book: { Params: BookingParams; Search: BookingSearch };
  bookConfirm: { Search: BookingConfirmSearch };
};

export type SchedulerPaths = MakePaths<SchedulerPathDefinitions>;

export const useSchedulerRoutes = (opts: MakeRoutesOpts) => {
  return makeRoutes(opts, [
    {
      path: paths.confirm,
      element: () => import("../components/AppointmentConfirmationPage"),
    },
    {
      path: paths.book,
      element: () => import("../components/AppointmentBooking"),
    },
    {
      path: paths.bookConfirm,
      element: () => import("../components/AppointmentBookingConfirm"),
    },
  ]);
};

export const makeSchedulerUrls = (base: string) => {
  return makeUrls<SchedulerPathDefinitions>(base, {
    confirm: { path: paths.confirm },
    book: {
      path: paths.book,
      to: ({ step }) => `/appointment/book/${step}/`,
    },
    bookConfirm: {
      path: paths.bookConfirm,
    },
  });
};
