import React, { useCallback, useEffect, useLayoutEffect, useRef, useState, RefObject } from "react";

import { ListSearch } from "common/types";
import { WithStringValues } from "common/utils/types";
import { useSearchState } from "common/router/hooks";
import { PartialGenerics } from "common/router/types";
import { updateListSearch } from "common/utils/queries";
import { TransitionDuration, TRANSITION_DURATIONS } from "common/constants/tokens";
import { bodyScroll } from "common/utils";

/**
 * Like {@link useSearchState} but specialized for list views
 * (views that are paginated and can be sorted, searched, and filtered)
 */
export const useListSearchState = <
  T extends PartialGenerics & { Search: WithStringValues<ListSearch> },
  S extends WithStringValues<ListSearch> = T["Search"],
>(
  defaults: S,
): readonly [S, (updates: Partial<S>) => void] => {
  const [params, setParams] = useSearchState<T, S>(defaults);
  return [
    params,
    (updates: Partial<S>) => {
      setParams(updateListSearch(params, updates));
    },
  ] as const;
};

/**
 * A hook that when mounted prevents scrolling on the body.
 */
export const useNoBodyScroll = () => {
  useEffect(() => {
    // Don't do anything if we are already preventing the scrolling, the first one to prevent will allow again via the
    // unmount function that runs at the end
    if (bodyScroll.isAlreadyPrevented()) {
      return () => {};
    }

    bodyScroll.prevent();

    return () => bodyScroll.allow();
  }, []);
};

export enum TransitionState {
  Entering = "entering",
  Entered = "entered",
  Exiting = "exiting",
  Exited = "exited",
}

export type Transition = {
  isMounted: boolean;
  transitionState: TransitionState;
  duration: number;
  durationStyle: string;
  isEntering: boolean;
  isEntered: boolean;
  isExiting: boolean;
  isExited: boolean;
};

/**
 * A hook based API similar to react-transition-group's <Transition /> component.
 */
export const useTransition = (
  visible: boolean,
  transitionDuration: TransitionDuration = "base",
): Transition => {
  // Set isMounted and the transition state separately so we can always transition from exited => entering
  const [isMounted, setIsMounted] = useState(false);
  const [transitionState, setTransitionState] = useState(TransitionState.Exited);
  const duration = TRANSITION_DURATIONS[transitionDuration];

  useLayoutEffect(() => {
    if (visible && !isMounted) {
      // Update the state in two separate renders
      setIsMounted(true);

      window.setTimeout(() => {
        setTransitionState(TransitionState.Entering);
      });
    }

    // Prevent starting in Exiting if the initial visibility is false
    if (!visible && isMounted) {
      setTransitionState(TransitionState.Exiting);
    }
  }, [visible, isMounted]);

  useLayoutEffect(() => {
    let timeout: number;

    if (transitionState === TransitionState.Entering) {
      timeout = window.setTimeout(() => {
        setTransitionState(TransitionState.Entered);
      }, duration);
    }

    if (transitionState === TransitionState.Exiting) {
      timeout = window.setTimeout(() => {
        // Update the state in two separate renders
        setTransitionState(TransitionState.Exited);

        window.setTimeout(() => {
          setIsMounted(false);
        });
      }, duration);
    }

    // Clear any queued updates when unmounting
    return () => clearTimeout(timeout);
  }, [transitionState, duration]);

  return {
    isMounted,
    transitionState,
    duration,
    durationStyle: `${duration}ms`,
    isEntering: transitionState === TransitionState.Entering,
    isEntered: transitionState === TransitionState.Entered,
    isExiting: transitionState === TransitionState.Exiting,
    isExited: transitionState === TransitionState.Exited,
  };
};

/**
 * A hook that keeps track of the previous value
 */
export const usePrevious = <T>(value: T) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T | undefined>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export type UpdateField<T> = <K extends keyof T>(fieldName: K) => (fieldValue: T[K]) => void;

/**
 * A hook to more easily manage form state. Generally we should favor
 * using this hook over a large handful of one-off `useState` hooks.
 * @typeParam T The type of the values that the form expect
 * @param initialValues The initial state of the form on first render
 * @returns A tuple containing:
 *   1. The current values.
 *   2. A factory to update fields for a given value.
 *   3. A function to set the values directly using standard react semantics.
 * @example
 * ```jsx
 * <Input value={values.email} onChange={updateField('email')} />
 * ```
 */
export const useFormState = <T>(initialState: T | (() => T)) => {
  const [values, setValues] = useState(initialState);

  const updateField: UpdateField<T> = useCallback(
    (fieldName) => (fieldValue) => {
      setValues((v) => ({ ...v, [fieldName]: fieldValue }));
    },
    [],
  );

  return [values, updateField, setValues] as const;
};

/**
 * A hook to debounce a value
 */
export const useDebounce = <T>(value: T, delay = 300) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-run if value or delay changes
  );

  return debouncedValue;
};

type Timeout = ReturnType<typeof setTimeout>;

/**
 * Hook to debounce a function within a react component render
 * @param callback function to debounce
 * @param delay before function is executed
 * @returns function instance to call with a cancel method added
 * @example
 * const debouncedLog = useDebouncedCallback((s: string) => console.log(s), 1000)
 * debouncedLog('one')
 * debouncedLog.cancel()
 */
export const useDebounceCallback = <T extends unknown[]>(
  callback: (...args: T) => void,
  delay = 300,
) => {
  const timeoutRef = useRef<Timeout>();

  useEffect(() => {
    // we only want to clear the timeout on the unmounting of this component so
    // it's okay that this value can change underneath us
    return () => timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  const debouncedCallback = (...args: T) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  debouncedCallback.cancel = () => timeoutRef.current && clearTimeout(timeoutRef.current);

  return debouncedCallback;
};

type UseDebounceMutationConfig<T> = {
  delay?: number;
  mergeFn?: (prev: T | undefined, next: T) => any;
};

/**
 * Hook to debounce a mutation and combine multiple updates into a single call
 *
 * @param mutation react query mutation
 * @param config options for the mutation call
 * @returns function instance to call
 */
export const useDebounceMutation = <T>(
  mutation: { mutate: (values: T) => void },
  config: UseDebounceMutationConfig<T> = {},
) => {
  const { delay = 300, mergeFn = (prev, next) => ({ ...prev, ...next }) } = config;
  const timeoutRef = useRef<Timeout>();
  const updatesRef = useRef<T>();

  const debouncedCallback = (values: T) => {
    updatesRef.current = mergeFn(updatesRef.current, values);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (updatesRef.current === undefined) {
        return;
      }

      mutation.mutate(updatesRef.current);
      updatesRef.current = undefined;
    }, delay);
  };

  return debouncedCallback;
};

/**
 * Returns a stateful value and a function that will update the value while also debouncing some given callback
 * Useful for managing TextInputs that we want to update in realtime
 * */
export const useDebounceValue = <T>(initialValue: T, onChange: (value: T) => void) => {
  const [value, setValue] = useState(initialValue);
  const debounceOnChange = useDebounceCallback(onChange);
  const onChangeHandler = (val: T) => {
    setValue(val);
    debounceOnChange(val);
  };
  return [value, onChangeHandler, setValue] as const;
};

/**
 * Given a ref, listen for any clicks that happen outside of it
 */
export const useClickOutside = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  onClickOutside: (event: MouseEvent) => void,
) => {
  const isFromOutsideMousedown = useRef(false);

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      isFromOutsideMousedown.current = !ref.current?.contains(event.target as Node);
    };

    const handleClick = (event: MouseEvent) => {
      if (!ref.current?.contains(event.target as Node) && isFromOutsideMousedown.current) {
        onClickOutside(event);
        event.stopPropagation();
      }
    };

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("click", handleClick);
    };
  }, [ref, onClickOutside]);
};

/**
 * A hook to apply an event listener, and remove that event listener on unmount
 *
 * Ported from https://usehooks.com/useEventListener/
 */
export const useEventListener = (
  eventName: string,
  handler: (event: Event) => void,
  element = window,
) => {
  const savedHandler = useRef<(event: Event) => void>(() => null);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;

    if (!isSupported) {
      return () => null;
    }

    const eventListener = (event: Event) => savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => element.removeEventListener(eventName, eventListener);
  }, [eventName, element, handler]);
};

/**
 * A hook to keep the horizontal scrolls of multiple elements in sync to help mimick sticky
 * behavior where sticky isn't possible due to nested scrolling
 * @param refs The refs of the elements that should stay in sync
 */
export const useHorizontalScrollSync = (refs: React.RefObject<HTMLElement>[]) => {
  useLayoutEffect(() => {
    const elements = refs.flatMap((ref) => (ref.current ? [ref.current] : []));

    const handleScroll = (event: Event) => {
      // useScrollSync is only ever used with HTMLElement so we cast here to get the type
      // information that's lost from the Event in the addEventListener callback
      const eventElement = event.target as HTMLElement;

      elements.forEach((element) => {
        if (element === eventElement) {
          return; // Don't update the scroll to the element doing the scrolling
        }

        element.scrollTo({ left: eventElement.scrollLeft });
      });
    };

    elements.forEach((element) => {
      element.addEventListener("scroll", handleScroll);
    });

    return () =>
      elements.forEach((element) => {
        element.removeEventListener("scroll", handleScroll);
      });
  }, [refs]);
};

const getIsHorizontalOverflow = (ref: React.RefObject<HTMLElement>) => {
  return !!(ref.current && ref.current.offsetWidth < ref.current.scrollWidth);
};

/** A hook to track whether an item is horizontally overflowing */
export const useHorizontalOverflow = (ref: React.RefObject<HTMLElement>) => {
  const [isOverflowing, setIsOverflowing] = useState(() => getIsHorizontalOverflow(ref));

  const updateIsOverflowing = useCallback(() => {
    setIsOverflowing(getIsHorizontalOverflow(ref));
  }, [ref]);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateIsOverflowing);

    return () => window.removeEventListener("resize", updateIsOverflowing);
  }, [updateIsOverflowing]);

  return { isOverflowing, updateIsOverflowing };
};

export type HorizontalScrollTrackOpts = {
  /** Called when the elements horizontal scroll reaches the end (within 15px) */
  onScrollEnd: () => void;
  onScrollOut: () => void;
};

/** A hook to track an elements horizontal scroll */
export const useHorizontalScrollTrack = (
  ref: React.RefObject<HTMLElement>,
  { onScrollEnd, onScrollOut }: HorizontalScrollTrackOpts,
) => {
  useLayoutEffect(() => {
    const elem = ref.current;

    const handleScroll = () => {
      if (elem) {
        const maxScrollLeft = elem.scrollWidth - elem.clientWidth;
        const scrollDiff = maxScrollLeft - elem.scrollLeft;
        // Scrolling slows down at the end, so we can trigger a bit before
        if (scrollDiff < 15) {
          onScrollEnd();
        } else {
          onScrollOut();
        }
      }
    };

    if (elem) {
      elem.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (elem) {
        elem.removeEventListener("scroll", handleScroll);
      }
    };
  }, [ref, onScrollEnd, onScrollOut]);
};

const getIsVerticalOverflow = (ref: React.RefObject<HTMLElement>) => {
  return ref.current && ref.current.clientHeight < ref.current.scrollHeight;
};

/** A hook to track whether an item is vertically overflowing */
export const useVerticalOverflow = (ref: React.RefObject<HTMLElement>) => {
  const [isOverflowing, setIsOverflowing] = useState(() => getIsVerticalOverflow(ref));

  const updateIsOverflowing = useCallback(() => {
    setIsOverflowing(getIsVerticalOverflow(ref));
  }, [ref]);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateIsOverflowing);

    return () => window.removeEventListener("resize", updateIsOverflowing);
  }, [updateIsOverflowing]);

  return { isOverflowing, updateIsOverflowing };
};

export type VerticalScrollTrackOpts = {
  /** Called when the elements horizontal scroll reaches the end (within 15px) */
  onScrollEnd: () => void;
  onScrollOut: () => void;
};

/** A hook to track an elements vertical scroll */
export const useVerticalScrollTrack = (
  ref: React.RefObject<HTMLElement>,
  { onScrollEnd, onScrollOut }: VerticalScrollTrackOpts,
) => {
  useLayoutEffect(() => {
    const elem = ref.current;

    const handleScroll = () => {
      if (elem) {
        const maxScrollTop = elem.scrollHeight - elem.clientHeight;
        const scrollDiff = maxScrollTop - elem.scrollTop;
        // Scrolling slows down at the end, so we can trigger a bit before
        if (scrollDiff < 15) {
          onScrollEnd();
        } else {
          onScrollOut();
        }
      }
    };

    if (elem) {
      elem.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (elem) {
        elem.removeEventListener("scroll", handleScroll);
      }
    };
  }, [ref, onScrollEnd, onScrollOut]);
};

const noop = () => null;

export type OnLeaveFocusOptions = {
  onEnterFocus?: () => void;
  onLeaveFocus?: () => void;
  startsFocused?: boolean;
};

/**
 * A hook that watches a given element, and applies a callback when
 * focus enters/leaves that element
 *
 * @param containerRef The container to track focus within
 * @param opts.onEnterFocus The callback to apply when focus enters the container
 * @param opts.onLeaveFocus The callback to apply when focus leaves the container
 * @param opts.startsFocused Whether the container enters focused (useful if this
 * hook needs to be called from within the already-rendered dropdown itself)
 * @returns
 */
export const useTrackFocus = (containerRef: RefObject<HTMLElement>, opts: OnLeaveFocusOptions) => {
  const { startsFocused = true, onEnterFocus = noop, onLeaveFocus = noop } = opts;
  const [isFocused, setIsFocused] = useState(startsFocused);
  const wasFocused = usePrevious(isFocused);

  const handleGlobalFocus = (e: FocusEvent) => {
    const container = containerRef.current;
    if (!container || !(e.target instanceof Node)) {
      return;
    }

    setIsFocused(container.contains(e.target));
  };

  useEffect(() => {
    // uses `focusin` because `focus` does not bubble
    document.addEventListener("focusin", handleGlobalFocus, true);

    return () => document.removeEventListener("focusin", handleGlobalFocus, true);
  });

  useEffect(() => {
    if (!isFocused && wasFocused) {
      onLeaveFocus();
    }
    // Prevent calling onEnterFocus on initial render
    else if (isFocused && !wasFocused && wasFocused !== undefined) {
      onEnterFocus();
    }
  }, [isFocused, wasFocused, onLeaveFocus, onEnterFocus]);
};

type UseFocusTrapConfig = {
  ref: RefObject<HTMLElement>;
  isFocused: boolean;
};

/**
 * Hook to trap and return focus.
 *
 * Can be activated by calling `trap`, suspended by calling `pause`, or stopped
 * (and focus returned) by calling `drop` All functions can be called repeatably
 * without any adverse effects.
 *
 * Calling `pause` or `drop` on a stopped/paused trap, or calling `trap` on an activated
 * trap will do nothing.
 * Calling `trap` on a paused trap will resume focus tracking.
 */
export const useFocusTrap = (config: UseFocusTrapConfig) => {
  const { ref: containerRef, isFocused } = config;

  const fromElementRef = useRef<Element | null>(null);

  const initializeFocus = useCallback(() => {
    fromElementRef.current = document.activeElement;

    // Only focus the container if no inner element is already focused
    if (containerRef.current && !containerRef.current.contains(document.activeElement)) {
      containerRef.current.focus();
    }
  }, [containerRef]);

  const trapFocus = useCallback(() => {
    const container = containerRef.current;

    if (!container) {
      return undefined;
    }

    const handleGlobalFocus = (e: FocusEvent) => {
      if (!container) {
        return;
      }

      if (e.target instanceof Node && !container.contains(e.target)) {
        e.preventDefault();
        container.focus();
      }
    };

    // uses `focusin` because `focus` does not bubble
    document.addEventListener("focusin", handleGlobalFocus, true);

    return () => {
      document.removeEventListener("focusin", handleGlobalFocus, true);
    };
  }, [containerRef]);

  const dropFocus = useCallback(() => {
    const element = fromElementRef.current;
    if (element && element instanceof HTMLElement) {
      // We need to wait for the next tick to focus the element, otherwise the
      // onFocus event is never called on that element
      setTimeout(() => {
        element?.focus();
      });
    }
    fromElementRef.current = null;
  }, []);

  useEffect(() => {
    // If we're not focused, don't trap the focus state here
    if (!isFocused) {
      return () => null;
    }

    // Initialize if we don't already have an element to drop back to
    if (!fromElementRef.current) {
      initializeFocus();
    }

    // Trap the focus to the container element
    return trapFocus();
  }, [initializeFocus, dropFocus, trapFocus, isFocused]);

  // Return the focus when unmounting
  useEffect(() => {
    return () => {
      dropFocus();
    };
  }, [dropFocus]);
};

/** Methods to control the timer */
export type TimerControl = {
  /** starts the timer from the beginning */
  restart: () => void;
  /** stops the timer by removing any remaining time */
  clear: () => void;
};

/**
 * Given a number of seconds, returns an API to manage a timer that starts at mount
 * time and completes after `seconds` seconds. Useful for displaying countdowns in the UI.
 *
 * @returns A tuple with:
 *   1. number - The number of seconds remaining
 *   2. {@link TimerControl} - Methods to control the state of the timer
 *  */
export const useTimer = (seconds: number): [number, TimerControl] => {
  const ms = seconds * 1000;
  const [start, setStart] = useState(new Date());
  const [secondsRemaining, setSecondsRemaining] = useState(ms);

  useEffect(() => {
    const updateTimer = () => {
      const now = new Date();
      const timePassed = now.getTime() - start.getTime();
      const remainingMs = Math.max(Math.floor(ms) - timePassed, 0);
      setSecondsRemaining(Math.ceil(remainingMs / 1000));
    };
    const interval = setInterval(updateTimer, 250); // significantly less than half a second to avoid sampling problems
    updateTimer();
    return () => clearInterval(interval);
  }, [ms, start]);

  return [
    secondsRemaining,
    {
      restart: useCallback(() => setStart(new Date()), []),
      clear: useCallback(() => setStart((current) => new Date(current.getTime() - ms)), [ms]),
    },
  ];
};

const easeOutQuad = (t: number) => t * (2 - t);
const frameDuration = 1000 / 60; // 60 FPS

export type CounterConfig = {
  initial: number;
  value: number;
  duration: number;
};

export const useCounter = ({ initial, value, duration }: CounterConfig) => {
  const [count, setCount] = useState(initial);

  const start = useCallback(() => {
    // Iterate and count at approximately 60 fps (adjust frameDuration if there is jank)
    let frame = 0;
    const totalFrames = Math.round(duration / frameDuration);
    const counter = setInterval(() => {
      frame += 1;
      const progress = easeOutQuad(frame / totalFrames);
      setCount(value * progress);

      // Once we're done, stop counting and trigger the circle animation
      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);
  }, [value, duration]);

  return { count: Math.floor(count), start };
};

/**
 * Prevents trackpads from naviating backward when scrolling all the way to the left
 * @see https://danburzo.ro/demos/overscroll-behavior.html
 */
export const useContainHorizontalScroll = () => {
  useEffect(() => {
    document.body.style.setProperty("overscroll-behavior-x", "contain");

    return () => {
      document.body.style.removeProperty("overscroll-behavior-x");
    };
  }, []);
};

type ControlledSelectionFocusOpts = {
  ref: RefObject<{ setSelectionRange: (start: number, end: number) => void; focus: () => void }>;
};

export const useControlledSelectionFocus = ({ ref }: ControlledSelectionFocusOpts) => {
  const [controlledSelectionStart, setControlledSelectionStart] = useState<number | null>(null);

  useEffect(() => {
    if (controlledSelectionStart !== null) {
      ref.current?.setSelectionRange(controlledSelectionStart, controlledSelectionStart);
      setControlledSelectionStart(null);
    }
    // we only care if the controlledSelectionStart changes because that is when we have re-focused the input
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlledSelectionStart]);

  return useCallback((newStart: number) => {
    const input = ref.current;
    if (!input) {
      return;
    }
    input.focus();
    setControlledSelectionStart(newStart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

type UseCurrentTimeConfig = {
  tickMs: number;
};

export const useCurrentTime = ({ tickMs }: UseCurrentTimeConfig) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const interval = useRef<NodeJS.Timeout>();

  useEffect(() => {
    interval.current = setInterval(() => {
      setCurrentTime(new Date());
    }, tickMs);
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [tickMs]);

  return currentTime;
};
