import React, { useEffect, useRef, useState } from "react";
import {
  faBoxHeart,
  faPaw,
  faQuestion,
  faRepeat,
  faRightFromBracket,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import cx from "classnames";

import { useClickOutside, useDebounce } from "common/hooks";
import { useAuthentication } from "common/auth/hooks";
import LinkButton from "common/components/LinkButton";
import Icon from "common/components/Icon";
import Spinner from "common/components/Spinner";
import { Optional } from "common/utils/types";

import { useActiveCartQuery } from "client/carts/hooks";
import { useSearchContext } from "client/common/hooks";
import { useClientQuery } from "client/accounts/hooks";
import { externalUrls, urls } from "client/routes";
import { useCartDrawer } from "client/carts/stackables";

import HeaderBanner, { HeaderBannerVariant } from "./HeaderBanner";
import MenuDrawerIconButton from "./MenuDrawerIconButton";
import Logo from "./Logo";
import Container from "../layouts/Container";
import ShoppingBag from "../icons/ShoppingBag";
import SearchIcon from "../icons/SearchIcon";
import HeaderAlerts from "./HeaderAlerts";

import styles from "../../styles/Header.module.less";

const SEARCH_PLACEHOLDER = "Find medications and preventatives....";

const HeaderSearchLink = () => {
  const { focusSearchInput } = useSearchContext();

  return (
    // button pretending to look like an input
    <button type="button" className={cx(styles.search, styles.input)} onClick={focusSearchInput}>
      <span className={styles.placeholder}>{SEARCH_PLACEHOLDER}</span>
      <SearchIcon className={styles.icon} />
    </button>
  );
};

type HeaderSearchProps = {
  autoFocus?: boolean;
  value: string;
  onSearch: (value: string) => void;
};

const HeaderSearch = ({ autoFocus, value, onSearch }: HeaderSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [input, setInput] = useState(value);

  const debounceInput = useDebounce(input);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    setInput(value);
  }, [value]);

  useEffect(() => {
    if (debounceInput !== value) {
      onSearch(debounceInput);
    }
    /* onSearch is okay to only change when debounceInput does */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [debounceInput]);

  const handleIconClick = () => {
    onSearch("");
    inputRef.current?.focus();
  };

  return (
    <div className={styles.search}>
      <input
        ref={inputRef}
        value={input}
        placeholder={SEARCH_PLACEHOLDER}
        onChange={(e) => setInput(e.target.value)}
        className={cx(styles.input, styles.dynamic, autoFocus && styles.autoFocus)}
        onFocus={(e) => e.target.select()}
      />
      <SearchIcon onClick={handleIconClick} isActive={!!input?.length} className={styles.icon} />
    </div>
  );
};

const AccountButton = ({ className }: { className?: string }) => {
  const { data: client } = useClientQuery();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useClickOutside(containerRef, () => setIsDropdownOpen(false));

  const links = [
    {
      label: "My account",
      dest: urls.accounts.detail(),
      icon: faUser,
    },
    {
      label: "My orders",
      dest: urls.orders.list(),
      icon: faBoxHeart,
    },
    {
      label: "My autoships",
      dest: urls.subscriptions.list(),
      icon: faRepeat,
    },
  ];

  return (
    <div className={styles.account} ref={containerRef}>
      <button
        type="button"
        onClick={() => setIsDropdownOpen((o) => !o)}
        className={cx(styles.nav, className)}
      >
        Account
      </button>
      <div className={cx(styles.popover, isDropdownOpen && styles.open)}>
        {client && (
          <div className={styles.client}>
            <Icon fixedWidth={true} className={styles.linkIcon} icon={faPaw} />
            <div>
              <div className={styles.name}>{client.user.name}</div>
              <div className={styles.phone}>{client.user.phone_number_display}</div>
            </div>
          </div>
        )}
        <div className={styles.links}>
          {links.map((link) => (
            <LinkButton key={link.label} className={styles.subnav} dest={link.dest}>
              <div className={styles.linkIconContainer}>
                <Icon fixedWidth={true} className={styles.linkIcon} icon={link.icon} />
              </div>
              {link.label}
            </LinkButton>
          ))}
        </div>
        <div>
          <a href={externalUrls.signout()} className={styles.subnav}>
            <div className={styles.linkIconContainer}>
              <Icon fixedWidth={true} className={styles.linkIcon} icon={faRightFromBracket} />
            </div>
            Signout
          </a>
        </div>
      </div>
    </div>
  );
};

const CartButton = ({ className }: { className?: string }) => {
  const { data: cart, isLoading, isError } = useActiveCartQuery();
  const { open: openCartDrawer } = useCartDrawer();

  const renderCount = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (isError) {
      return <Icon icon={faQuestion} />;
    }

    return cart?.items.length || 0;
  };

  return (
    <button type="button" onClick={() => openCartDrawer()} className={cx(styles.cart, className)}>
      {renderCount()}
      <ShoppingBag />
    </button>
  );
};

type HeaderStyles = {
  banner?: string;
  menuIcon?: string;
  cartButton?: string;
  nav?: string;
};

type HeaderProps = {
  Search?: React.ReactNode;
  storefrontThemeLogo?: Optional<string>;
  bannerVariant?: HeaderBannerVariant;
  hasWhiteIcons?: boolean;
  styles?: HeaderStyles;
};

const Header = ({
  Search = <HeaderSearchLink />,
  storefrontThemeLogo,
  bannerVariant,
  hasWhiteIcons = false,
  styles: propStyles = {},
}: HeaderProps) => {
  const { isAuthenticated } = useAuthentication();

  return (
    <div className={cx(styles.container, hasWhiteIcons)}>
      <HeaderAlerts />
      <HeaderBanner className={cx(styles.banner, propStyles.banner)} variant={bannerVariant} />
      <Container>
        <div className={styles.content}>
          <div className={styles.drawer}>
            <MenuDrawerIconButton className={propStyles.menuIcon} isWhite={hasWhiteIcons} />
          </div>
          <div className={styles.logoContainer}>
            <Logo
              className={styles.logo}
              storefrontThemeLogo={storefrontThemeLogo}
              isWhite={hasWhiteIcons}
            />
          </div>
          <div className={styles.searchbar}>{Search}</div>
          <div className={styles.navigation}>
            {isAuthenticated && (
              <LinkButton
                dest={urls.prescriptions.list()}
                className={cx(styles.nav, propStyles.nav, styles.prescriptions)}
              >
                Prescriptions
              </LinkButton>
            )}
            {isAuthenticated ? (
              <AccountButton className={propStyles.nav} />
            ) : (
              <LinkButton dest={urls.accounts.login()} className={cx(styles.nav, propStyles.nav)}>
                Account
              </LinkButton>
            )}
            <CartButton className={propStyles.cartButton} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Object.assign(Header, { Search: HeaderSearch, SearchLink: HeaderSearchLink });
