import { IItemWithProduct, SubscriptionFrequency, IPatient, FlavorSelection } from "common/types";
import {
  CartDeliveryUpdate,
  ControlledSubstanceLevel,
  ICartItem,
  IDeliveryGroup,
  RefillTooSoonReason,
} from "common/types/carts";
import { Optional } from "common/utils/types";

/** Matches carts/constants CartCode */
export enum CartCode {
  ACTIVE = "active",
}

/** Matches carts ClientCartItemDetailSerializer  */
export interface ICartItemDetail {
  id: number;
  item: IItemWithProduct;
  quantity: number;
  is_pickup: boolean;
  patient_id: Optional<number>;
  is_subscription: boolean;
  is_subscribable: boolean;
  default_unit_price: number;
  custom_unit_price: Optional<number>;
  unit_discount: number;
  refill_too_soon_reason: Optional<RefillTooSoonReason>;
}

export type CartDeliveryMutate = {
  body: CartDeliveryUpdate[];
  optimisticData: {
    newDeliveryGroups?: IDeliveryGroup[];
    newCartItems?: ICartItem[];
    isAllPickup?: boolean;
    isDeliveryInfoComplete?: boolean;
  };
};
/** Matches carts/types ClientCartItemUpdateSerializerV2 * */
export interface CartItemUpdateV2 {
  quantity?: number;
  patient?: number | null;
  refill_too_soon_reason?: RefillTooSoonReason | null;
  // TODO: sc46617p3
  selections?: number[];
  flavor_selection?: number;
}

/** This mutation will update the CI detail view, but needs data to opportunistically update the cart detail view * */
export interface ICartItemMutateV2 {
  body: CartItemUpdateV2;
  optimisticData?: {
    patient?: IPatient;
    // TODO: sc46617p3
    selections?: FlavorSelection[];
    flavor_selection?: FlavorSelection;
  };
}

/** Matches carts ClientCartItemDetailSerializer _writable_fields */
export interface ICartItemUpdate {
  quantity?: number;
  patient_id?: number | null;
  item?: number;
  refill_too_soon_reason?: RefillTooSoonReason | null;
}
/** Matches carts CartController.assign_cart_items */
export interface ICartItemAssignment {
  cart_item_id: number;
  assignments: {
    patient_id: number;
    quantity: number;
  }[];
}

/**
 * Matches carts ClientAddToCartSerializer _writable_fields
 */
export interface ICartItemCreate {
  item: number;
  cart_id: number | CartCode.ACTIVE;
  // TODO: sc46617p3
  selections?: number[];
  flavor_selection?: number;
  subscription?: SubscriptionFrequency;
  quantity?: number;
  is_pickup?: boolean;
  patient_id?: number | null;
}

/**
 * TODO ch17657 currently this API is not well-defined and shared between client/clinic views.
 * We may want to come back here, lock this down, and update the types to match some
 * serializer
 * tracked at: https://app.shortcut.com/vetcove/story/17657/lock-down-cart-types-fe-facing-interfaces
 */
export interface ICartUpdate {
  shipping_address_id: number | null;
  payment_method_id: number | null;
  note: string;
  is_urgent: boolean;
  drivers_license_number: string;
  drivers_license_state: string;
  date_of_birth: string;
}

/**
 * Matches carts ClientCheckoutSerializer
 */
export interface ClientCheckout {
  is_klarna_checkout: boolean;
}

/** matches carts CartItemRefillTooSoonSummaryData */
export type CartItemRefillTooSoonSummary = {
  id: number;
  is_valid_by_refill_too_soon: boolean;
  earliest_date_to_refill: Optional<string>;
};

/** matches carts ClientCartRefillTooSoonSummaryView */
export type CartRefillTooSoonSummary = {
  cart_items: CartItemRefillTooSoonSummary[];
};

/** constant used to check for promo code in the URL param when directed from a marketing email with a promotion */
export const PROMO_CODE_SEARCH_PARAM = "cart-promo-code";

/** matches carts ClientControlledSubstancesSummaryValidationSerializer */
export type ControlledSubstancesSummaryMutation = {
  address_id: number | undefined;
  item_ids: number[];
};

/** matches carts ClientControlledSubstancesSummaryView post response */
export type ControlledSubstancesSummary = {
  is_controlled: boolean;
  controlled_level: ControlledSubstanceLevel;
  required_fields: string[];
  missing_fields: string[];
  controlled_product_line_ids: number[];
};
