import React from "react";
import cx from "classnames";

import LinkButton from "common/components/LinkButton";
import Image from "common/components/Image";
import { Optional } from "common/utils/types";

import { useClinic } from "client/clinics/hooks";
import { urls } from "client/routes";

import styles from "../../styles/Logo.module.less";

type LogoProps = {
  className?: string;
  isWhite?: boolean;
  storefrontThemeLogo?: Optional<string>;
};

const Logo = ({ className, isWhite = false, storefrontThemeLogo }: LogoProps) => {
  const { logo: defaultLogo, display_name: name } = useClinic();

  const { logo, isThemeLogo } = storefrontThemeLogo
    ? { logo: storefrontThemeLogo, isThemeLogo: true }
    : { logo: defaultLogo, isThemeLogo: false };

  return (
    <LinkButton
      // if it's supposed to be a white logo and we're using a default or text logo, let's white-ify the logo
      className={cx(styles.container, isWhite && !isThemeLogo && styles.white, className)}
      dest={urls.home.root()}
    >
      {logo ? (
        <Image src={logo} alt={name} />
      ) : (
        <div className={styles.textLogo}>
          <h5 className={styles.title}>{name}</h5>
        </div>
      )}
    </LinkButton>
  );
};

export default Logo;
