import {
  FrequencyUnit,
  IItemAndFlavorOptionsWithProduct,
  IPatient,
  UserSubscriptionAction,
  IPaymentMethod,
  IAddress,
  UISubscriptionStatus,
  FlavorSelection,
} from "common/types";
import { Optional } from "common/utils/types";
import { Incentive } from "common/types/promotions";
import { ISimpleOrder } from "common/types/orders";

import { ModalStatus } from "client/common/types";
import { ISubscriptionPrescription } from "client/prescriptions/types";
import { OnboardingSubscriptionCancelReason } from "client/onboarding/types";

interface ISubscriptionFrequencyOption {
  frequency: number;
  interval_unit: FrequencyUnit;
  display: string;
  next_order_dates: string[];
}

/** Matches subscriptions.subscription_pricing_controller SubscriptionDiscountBreakdown  */
export interface SubscriptionDiscountBreakdown {
  display: string;
  discount: number;
  incentive: Incentive;
  rebase: never;
}

/** Matches subscriptions.subscription_pricing_controller SubscriptionPricing */
export interface SubscriptionPricing {
  unit_price: number;
  quantity: number;
  subtotal: number;
  discount: number;
  handling_fee: number;
  client_fees: number;
  shipping: number | null;
  discount_breakdown: SubscriptionDiscountBreakdown[];
  total: number;
}

/** matches subscriptions ClientSubscriptionBaseSerializer */
export interface BaseSubscription {
  id: number;
  // TODO sc48175 make a more specific subscription type without these
  item: IItemAndFlavorOptionsWithProduct;
  patient: IPatient;
  payment_method: Optional<IPaymentMethod>;
  shipping_address: IAddress;
  created: string;
  potential_user_actions: [UserSubscriptionAction, string][];
  last_order?: ISimpleOrder;
  active_next_order_date?: string;
  selections: FlavorSelection[];
  flavor_selection: number | null;
  selections_display: Optional<string>;
  flavors_display: Optional<string>;
  prescription_warning: Optional<string>;
  skip_order_date: string;
  active_frequency: ISubscriptionFrequencyOption;
  recommended_frequency: ISubscriptionFrequencyOption;
  alternative_frequencies: ISubscriptionFrequencyOption[];
  is_active_frequency_valid: boolean;
  visible_status: UISubscriptionStatus;
  visible_status_display: string;
  prescription: Optional<ISubscriptionPrescription>;
  next_order_data: SubscriptionPricing;
  display_number: string;
  action: UserSubscriptionAction;
  interval_unit: FrequencyUnit;
  frequency: number;
  next_order_date: string;
  quantity: number;
  prescription_needs_renewal_for_next_order: boolean;
  should_show_employee_pricing_note: boolean;
}

/** Matches subscriptions ClientSubscriptionListSerializer */
export interface ListSubscription extends BaseSubscription {}
export type SubscriptionListSearch = {
  visible_status?: UISubscriptionStatus[];
};

/** Matches subscriptions ClientSubscriptionDetailSerializer */
export interface DetailSubscription extends BaseSubscription {}

/** Matches writable data in ClientSubscriptionDetailSerializer */
export interface SubscriptionUpdate {
  action: UserSubscriptionAction;
  interval_unit?: FrequencyUnit;
  frequency?: number;
  next_order_date?: string;
  quantity?: number;
  shipping_address_id?: number;
  item_selections?: number[];
  flavor_selection?: number;
  cancel_reason?: OnboardingSubscriptionCancelReason;
  cancel_reason_other?: string;
}

/** Matches expected data in ClientSubscriptionPaymentView */
export interface SubscriptionPaymentUpdate {
  payment_method_id: number;
}

export enum SubscriptionForm {
  UpdateQuantity = "updateQuantity",
  UpdateFrequency = "updateFrequency",
  UpdateAddress = "updateAddress",
  UpdatePayment = "updatePayment",
  UpdateNextOrder = "updateNextOrder",
  SkipNextOrder = "skipNextOrder",
  Pause = "pause",
  Cancel = "cancel",
  Resume = "resume",
}

export type SubscriptionFormProps = {
  subscription: BaseSubscription;
  visible: boolean;
  onClose: () => void;
};

export type SubscriptionSearch = {
  form?: SubscriptionForm;
  visible?: ModalStatus;
};

export type BulkUpdateSubscriptions = {
  subscription_ids: number[];
  shipping_address_id?: number;
  payment_method_id?: number;
};

export type BulkUpdateSubscriptionsResponse = {
  updated_subscription_count: number;
};
