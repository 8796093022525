import { UISubscriptionStatus, UserSubscriptionAction } from "common/types";
import { parseNumber } from "common/utils/numbers";
import { toDollars, toPercent } from "common/utils/formatting";
import { Optional } from "common/utils/types";
import { Destination } from "common/router/types";

import type { StatusColor } from "client/common/components/content/Status";
import { ModalStatus } from "client/common/types";
import { urls } from "client/routes";

import { BaseSubscription, SubscriptionForm } from "../types";

export const genSubscriptionDiscountContent = <T extends any>(
  firstDiscount: string,
  recurringDiscount: string,
  {
    none,
    onlyFirst,
    onlyRecurring,
    both,
  }: {
    none: () => T;
    onlyFirst: (first: string) => T;
    onlyRecurring: (recurring: string) => T;
    both: (first: string, recurring: string) => T;
  },
) => {
  const hasFirst = !!parseNumber(firstDiscount, 0);
  const hasRecurring = !!parseNumber(recurringDiscount, 0);

  if (!hasFirst && !hasRecurring) {
    return none();
  }
  if (!hasRecurring && hasFirst) {
    return onlyFirst(toPercent(firstDiscount));
  }
  if ((hasRecurring && !hasFirst) || recurringDiscount === firstDiscount) {
    return onlyRecurring(toPercent(recurringDiscount));
  }
  return both(toPercent(firstDiscount), toPercent(recurringDiscount));
};

export const genSubscriptionMaxDiscountContent = <T extends any>(
  firstMax: Optional<number>,
  recurringMax: Optional<number>,
  {
    none,
    onlyFirst,
    onlyRecurring,
    both,
  }: {
    none: () => T;
    onlyFirst: (first: string) => T;
    onlyRecurring: (recurring: string) => T;
    both: (first: string, recurring: string) => T;
  },
) => {
  const hasFirst = !!firstMax;
  const hasRecurring = !!recurringMax;

  if (!hasFirst && !hasRecurring) {
    return none();
  }
  if (!hasRecurring && hasFirst) {
    return onlyFirst(toDollars(firstMax, { roundCents: true }));
  }
  if ((hasRecurring && !hasFirst) || firstMax === recurringMax) {
    return onlyRecurring(toDollars(recurringMax, { roundCents: true }));
  }
  return both(
    toDollars(firstMax, { roundCents: true }),
    toDollars(recurringMax, { roundCents: true }),
  );
};

const exhaustiveStatusCheck = (status: never) => {
  console.warn(`Missing order status label for ${status}`);
  return "Unknown";
};

export const getLabelForSubscriptionStatus = (status: UISubscriptionStatus) => {
  switch (status) {
    case UISubscriptionStatus.Active:
      return "Active";
    case UISubscriptionStatus.Canceled:
      return "Canceled";
    case UISubscriptionStatus.Paused:
      return "Paused";
    case UISubscriptionStatus.FailedPayment:
      return "Failed Payment";
    case UISubscriptionStatus.Imported:
      return "Imported";
    default:
      return exhaustiveStatusCheck(status);
  }
};

const exhaustiveColorCheck = (status: never): StatusColor => {
  console.warn(`Missing order status color for ${status}`);
  return "greyscale";
};

export const getColorForSubscriptionStatus = (status: UISubscriptionStatus): StatusColor => {
  switch (status) {
    case UISubscriptionStatus.Active:
      return "success";
    case UISubscriptionStatus.Paused:
      return "warning";
    case UISubscriptionStatus.FailedPayment:
      return "danger";
    case UISubscriptionStatus.Canceled:
    case UISubscriptionStatus.Imported:
      return "greyscale";
    default:
      return exhaustiveColorCheck(status);
  }
};

const isPrimary = (action: UserSubscriptionAction) => action === UserSubscriptionAction.OrderNow;

type DynamicUserSubscriptionAction = {
  action: UserSubscriptionAction;
  label: string;
  dest: Destination;
  isPrimary: boolean;
};

export const getSubscriptionUserActions = (
  subscription: BaseSubscription,
): DynamicUserSubscriptionAction[] => {
  const subscriptionActionToLink: Record<UserSubscriptionAction, Destination | undefined> = {
    [UserSubscriptionAction.Resume]: urls.subscriptions.detail({
      params: { id: subscription.id },
      search: {
        form: SubscriptionForm.Resume,
        visible: ModalStatus.Open,
      },
    }),
    [UserSubscriptionAction.Pause]: urls.subscriptions.detail({
      params: { id: subscription.id },
      search: {
        form: SubscriptionForm.Pause,
        visible: ModalStatus.Open,
      },
    }),
    [UserSubscriptionAction.Cancel]: urls.subscriptions.detail({
      params: { id: subscription.id },
      search: {
        form: SubscriptionForm.Cancel,
        visible: ModalStatus.Open,
      },
    }),
    [UserSubscriptionAction.Skip]: urls.subscriptions.detail({
      params: { id: subscription.id },
      search: {
        form: SubscriptionForm.SkipNextOrder,
        visible: ModalStatus.Open,
      },
    }),
    // Use add to cart button instead
    [UserSubscriptionAction.OrderNow]: undefined,
    // Not suported directly on the client side
    [UserSubscriptionAction.Modify]: undefined,
  };

  return (
    subscription.potential_user_actions
      // Set up the needed values
      .map(([action, label]) => ({
        action,
        label,
        dest: subscriptionActionToLink[action],
        isPrimary: isPrimary(action),
      }))
      // Remove any actions without a handler
      .flatMap((action) => (action.dest ? [action as typeof action & { dest: Destination }] : []))
      // Sort so that primary actions are at the end
      .sort((_a, b) => (b.isPrimary ? -1 : 0))
  );
};
