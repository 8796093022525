import { Optional } from "common/utils/types";

/** Enum as a reference of HTTP status codes that we use */
export enum StatusCode {
  /** There is no content to send for this request, but the headers may be useful.
   * The user agent may update its cached headers for this resource with the new ones.
   *
   * We generally use this when an operation was successful on a resource but that
   * resource no longer exists (eg. DELETE).
   * */
  NoContent = 204,
  /**
   * The server could not understand the request due to invalid syntax.
   *
   * We generally use this to express invalid data for the FE to display in forms.
   */
  BadRequest = 400,
  /**
   * Although the HTTP standard specifies "unauthorized", semantically this response means "unauthenticated".
   * That is, the client must authenticate itself to get the requested response.
   *
   * We generally use this to express an unauthenticated session, meaning the user should be redirected to
   * login.
   */
  Unauthorized = 401,
  /**
   * The client does not have access rights to the content; that is, it is unauthorized,
   * so the server is refusing to give the requested resource. Unlike 401 Unauthorized,
   * the client's identity is known to the server.
   *
   * We generally use this to express a lack of permissions.
   */
  Forbidden = 403,
  /**
   * The server can not find the requested resource. In the browser, this means the URL is not recognized.
   * In an API, this can also mean that the endpoint is valid but the resource itself does not exist.
   * Servers may also send this response instead of 403 Forbidden to hide the existence of a resource from an unauthorized client.
   * This response code is probably the most well known due to its frequent occurrence on the web.
   *
   * We generally use this to specify that a resource does not exist, and that information should be displayed to the user.
   */
  NotFound = 404,
  /**
   * The server has encountered a situation it does not know how to handle.
   */
  InternalServerError = 500,
}

export type RequestBody = { [key: string]: any } | void;

export type FetchMethod = "GET" | "PUT" | "PATCH" | "POST" | "DELETE";

export interface FetchRequestOpts<T extends RequestBody> {
  url: string;
  method?: FetchMethod;
  body?: T;
  responseContentType?: ResponseContentType;
}

export interface FakeFetchOpts {
  /** the time to the simulated request should take, defaults to 1000ms */
  delayMs?: number;
}

/** Unlike the real DRF field errors that is a partial representation of these, requires every field error to be set to something
 * Useful for typing in forms, to ensure that error type matches consumed type.
 *
 * This is required because the combination of `Partial` + loose typing would otherwise
 * allow pretty much any field error through if passed as a prop, not flagging field name changes
 */
export type AllFieldErrors<T, Extra = {}> = {
  [K in keyof T]:
    | (T[K] extends Optional<any[]>
        ? string[] // arrays are technically objects, so let's handle those first to handle like most primitives
        : T[K] extends Optional<object> // objects can be nested so let's get recursive
        ? AllFieldErrors<T[K], Extra>
        : string[])
    | undefined;
} & Extra;

/**
 *  DRF (Django Rest Framework) based type for field errors for a `FetchError`
 *  Also includes "non_field_errors" which are field errors that aren't tied to an individual field.
 * @example
 * ```json
 * {
 *   "username": ['No user found'],
 *   "non_field_errors": ['Could not find user with that password`],
 *   "profile": {
 *     "first_name": ["Invalid characters."]
 *   }
 * }
 * ```
 */
export type FieldErrors<T> = Partial<AllFieldErrors<T, { non_field_errors: string[] }>>;

/**
 * Errors that could be triggered by calls to fetcher
 * @typeParam T The type of the request that this fetch error is returned for.
 *   Generally the response shape will map directly to fields in the request
 * @typeParam D The type of any extra meta-data returned with the error.
 *   This is generally not used, but may be useful in some instances.
 */
export interface FetchError<T = {}, D = {}, ErrorsOverride = {}> extends Error {
  /** Status of the response. Ex: 400/404/500 */
  status: number;
  /** Django Rest exceptions details.
   * @example "Authentication credentials were not provided." */
  detail: string;
  /** @see {@link fetch/types!FieldErrors} for example */
  errors: Omit<FieldErrors<T>, keyof ErrorsOverride> & ErrorsOverride;
  /** Any extra meta-data returned with the error */
  data: Optional<D>;
}

export enum FatalFetchErrorKind {
  Unauthorized = "unauthorized",
  InvalidCSRFToken = "invalid_token",
}

export type ResponseContentType = "application/json" | "application/pdf";
