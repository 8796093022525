import React, { useEffect } from "react";

import { PROMO_CODE_SEARCH_PARAM } from "client/carts/types";
import {
  UTM_PARAMETERS_LIST,
  UTM_PARAM_TO_CAMPAIGN_JOURNEY_FIELD,
  CampaignJourneyCreate,
} from "client/marketing/types";
import { useAddCartPromoCodesMutation } from "client/carts/hooks";
import { useCreateCampaignJourney } from "client/marketing/hooks";

type CartProviderProps = {
  children: React.ReactNode;
};

/** Sets up and manages query parameters and general cart state */
const CartProvider = ({ children }: CartProviderProps) => {
  const { mutate: applyPromoCodes } = useAddCartPromoCodesMutation();
  const { mutate: applyCampaignRebate } = useCreateCampaignJourney();

  // block is meant to check for a query param "cart-promo-code"
  // so we can apply it to the cart then remove the query param from the URL without redirecting
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const promoCodes =
      queryParameters
        .get(PROMO_CODE_SEARCH_PARAM)
        ?.split(",")
        .filter((code) => code) ?? [];
    if (promoCodes.length) {
      applyPromoCodes(promoCodes);
      queryParameters.delete(PROMO_CODE_SEARCH_PARAM);
      const newURL = queryParameters.toString()
        ? `${window.location.pathname}?${queryParameters.toString()}`
        : window.location.pathname;
      window.history.replaceState({}, document.title, newURL);
    }
  }, [applyPromoCodes]);

  // block is meant to check for utm parameters from marketing campaign links
  // so we can apply it to the cart
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const campaignJourney: CampaignJourneyCreate = {
      source: "",
      medium: "",
      campaign_slug: "",
      content: "",
      term: "",
    };
    UTM_PARAMETERS_LIST.forEach((key) => {
      const utmParamValue = queryParameters.get(key);
      if (utmParamValue) {
        const campaignJourneyKey = UTM_PARAM_TO_CAMPAIGN_JOURNEY_FIELD[key];
        campaignJourney[campaignJourneyKey] = utmParamValue;
      }
    });
    // only perform utm tracking if we have the required utm parameters to create a 'CampaignJourney'
    // in the backend
    const hasRequiredUTMParams = !!campaignJourney.campaign_slug && !!campaignJourney.medium;
    if (hasRequiredUTMParams) {
      applyCampaignRebate(campaignJourney);
    }
  }, [applyCampaignRebate]);

  return <>{children}</>;
};

export default CartProvider;
