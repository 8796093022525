import { capitalized, pluralized } from "./strings";
import { Optional } from "./types";

/**
 * Takes a number and returns a formatted count
 * @param n the amount to count
 * @param singular the singular form of the entity we are counting
 * @param plural the plural form of the entity we are counting (defaults to simply appending an "s")
 */
export const toCount = (n: number, singular: string, plural?: string): string => {
  if (Number.isNaN(n)) {
    console.warn(`Cannot display count for ${n} items, defaulting to 0`);
    return toCount(0, singular, plural);
  }
  const pluralizedVal = pluralized(n, singular, plural);
  return pluralizedVal ? `${n.toLocaleString()} ${pluralizedVal}` : n.toLocaleString();
};

/**
 * Converts cents (our standard money representation) to it's dollar representation
 * @param cents the amount of money in cents
 * @returns the dollar representation
 */
export const toDollarValue = (cents: number) => (Object.is(cents, -0) ? 0 : cents / 100);

export type ToDollarsOpts = {
  /** Whether the value should be rounded to the nearest dollar
   * and displayed without the decimal */
  roundCents?: boolean;
  /** Whether the returned dollar string should be prefixed with a '+',
   * if the dollar amount is positive */
  showPositiveSign?: boolean;
  /* Whether the value should be rounded to the nearest cent */
  roundToNextCent?: boolean;
  /** Whether the value should be collapsed to whole dollars. If this value is true
   * we will not show cents if their are none.
   */
  collapseWholeDollars?: boolean;
};

/**
 * Formats a number of cents as a dollar amount
 * @param cents The value (in cents) to format
 * @param options Any options to control the formatting
 */
export const toDollars = (
  cents: Optional<number>,
  { roundCents, showPositiveSign, roundToNextCent, collapseWholeDollars }: ToDollarsOpts = {
    roundCents: false,
    showPositiveSign: false,
    roundToNextCent: false,
    collapseWholeDollars: false,
  },
) => {
  if (typeof cents !== "number") {
    return "";
  }
  const roundedCents = roundToNextCent ? Math.ceil(cents) : cents;
  const dollars = toDollarValue(roundedCents);

  const formatOpts: Intl.NumberFormatOptions = {
    style: "currency",
    currency: "USD",
    ...(roundCents ? { minimumFractionDigits: 0, maximumFractionDigits: 0 } : {}),
  };
  const dollarString = dollars.toLocaleString("en-US", formatOpts);

  const positiveSign = cents > 0 && showPositiveSign ? "+" : "";
  const dollarsWithSign = positiveSign + dollarString;

  if (collapseWholeDollars) {
    // this is supported by default in more modern browsers, but not by node until
    // v19 using `trailingZeroDisplay`. https://github.com/nodejs/node/issues/41568
    return dollarsWithSign.replace(/\.00$/, "");
  }
  return dollarsWithSign;
};

/**
 * Creates a string representing a percent from a float with four decimal places,
 * handling floating point issues by fixing the number of visible digits
 * e.g. 0.1234 -> '12.34%'
 * @param value the decimal value to convert to a percent
 */
export const toPercent = (value: string) => {
  if (!value) {
    return value;
  }
  const number = Number((parseFloat(value) * 100).toFixed(2));
  if (Number.isNaN(number)) {
    return "";
  }

  return `${number}%`;
};

/**
 * Formats an entity name
 * @param name The singular name of the entity
 * @param quantity The quantity of the entity (defaults to 0)
 * @param plural The plural name of the entity (defaults to singular name with an 's' appended)
 * */
export const toEntityName = (name: string, quantity: number = 0, plural?: string) =>
  capitalized(pluralized(quantity, name, plural));

export type PhoneFormatOpts = {
  /** If the phone number should include the extension part or not. If set to
   * `false` any extra digits will be trimmed from the end.
   */
  includeExtension?: boolean;
};

/**
 * Formats a partial 10 digit phone number (with possible extension) from a string or number
 * Adds following symbol for partial phone numbers to be used by for displaying
 * partial phone numbers in inputs
 *
 * @param val the 10 digit value to format
 * @returns formatted phone number
 * */
export const toPhone = (
  val: Optional<number | string>,
  { includeExtension = true }: PhoneFormatOpts = {},
): string => {
  if (val === undefined || val === null) {
    return "";
  }
  const valStr = val.toString();
  const { length } = valStr;
  if (!length) {
    return "";
  }
  if (length < 3) {
    return `(${valStr}`;
  }
  if (length === 3) {
    return `(${valStr}) `;
  }
  if (length < 6) {
    return `(${valStr.slice(0, 3)}) ${valStr.slice(3, 6)}`;
  }
  if (length === 6) {
    return `(${valStr.slice(0, 3)}) ${valStr.slice(3, 6)}-`;
  }
  if (length <= 10 || !includeExtension) {
    return `(${valStr.slice(0, 3)}) ${valStr.slice(3, 6)}-${valStr.slice(6, 10)}`;
  }
  return `(${valStr.slice(0, 3)}) ${valStr.slice(3, 6)}-${valStr.slice(6, 10)} x${valStr.slice(
    10,
    length,
  )}`;
};

/**
 * Gets the ordinal for a number
 *
 * 1 -> st
 * 2 -> nd
 * 3 -> rd
 * 4 -> th
 * 11 -> th
 * 32 -> nd
 * 38 -> th
 */
export const toOrdinal = (number: number) => {
  const ordinals = ["th", "st", "nd", "rd"];
  const value = number % 100;
  return ordinals[(value - 20) % 10] || ordinals[value] || ordinals[0];
};
